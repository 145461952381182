import {
    Button,
    Col,
    FormInstance,
    FormProps,
    Input,
    Layout,
    Modal,
    Row,
    Space,
    Typography,
    notification
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import CustomForm from '../shared/CustomForm';
import PetitionForWaiverDTO from '../../models/PetitionForWaiverDTO';
import FormItem from 'antd/lib/form/FormItem';
import FormValidationUtil from '../../utils/FormValidationUtil';
import AddressFormFields from '../shared/AddressFormFields';
import CustomDatePicker from '../shared/CustomDatePicker';
import CurrencyInput from '../shared/CurrencyInput';
import TextArea from 'antd/lib/input/TextArea';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import WaiverTypeSearch from '../shared/WaiverTypeSearch';
import CampaignApiService from '../../api/CampaignApiService';
import moment from 'moment';
import PenaltyType from '../../consts/PenaltyType';
import AdminPetitionForWaiverAPIService from '../../api/AdminPetitionForWaiverAPIService';

const { Content } = Layout;
const { confirm } = Modal;

interface PetitionForWaiverProps {
    id?: string;
}

interface PetitionForWaiverState {
    id?: number;
    loading: boolean;
    petitionForWaiver: PetitionForWaiverDTO;
    penaltyType: string;
}

class PetitionForWaiver extends React.Component<RouteComponentProps<PetitionForWaiverProps> & FormProps, PetitionForWaiverState>
{
    private readonly formRef = React.createRef<FormInstance>();

    constructor(props: RouteComponentProps<PetitionForWaiverProps> & FormProps) {
        super(props);

        this.state = {
            id: props.match.params.id ? parseInt(props.match.params.id) : undefined,
            loading: true,
            petitionForWaiver: PetitionForWaiverDTO.create({ reportDueDate: null, penaltyReceivedDate: null, signedDate: moment().startOf('day') }),
            penaltyType: ''
        };
    }

    componentDidMount() {
        this.getPetitionForWaiver();
    }

    render() {
        const { loading, id, petitionForWaiver } = this.state;

        const columnSizing = { xs: 24, md: 12 };

        return (
            <Content className="content-pad">
                {!loading &&
                    <CustomForm
                        formRef={this.formRef}
                        onFinish={this.submitForm}
                        initialValues={petitionForWaiver}
                        layout={'vertical'}
                        validateTrigger={['onChange', 'onBlur']}>
                        <Typography.Title level={5}>
                            Request a Waiver
                        </Typography.Title>
                        <WaiverTypeSearch
                            name={'waiverTypeName'}
                            required={true}
                            currContriType={this.state.penaltyType}
                            onContributorChange={this.handleWaiverTypeChange}
                            onSelect={this.handleContributorSelect}
                        />
                        <AddressFormFields
                            firstName={{ name: 'firstName' }}
                            middleInitial={{ name: 'middleInitial' }}
                            lastName={{ name: 'lastName' }}
                            address1={{ name: 'addressLine1' }}
                            address2={{ name: 'addressLine2' }}
                            city={{ name: 'city' }}
                            state={{ name: 'stateCd' }}
                            zip={{ name: 'zip' }}
                            phone={{ name: 'phone' }}
                            email={{ name: 'email' }}
                        />
                        <FormItem
                            name={'reportDueDate'}
                            label={'Due Date'}
                            rules={[FormValidationUtil.RequiredDate('Due Date is required')]}>
                            <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                        </FormItem>
                        <FormItem
                            name={'penaltyAmount'}
                            label={'Penalty Amount'}
                            rules={[
                                FormValidationUtil.RequiredNumber('Amount is required'),
                                FormValidationUtil.PositiveNumber('Amount must be positive')
                            ]}>
                            <CurrencyInput />
                        </FormItem>
                        <FormItem
                            name={'penaltyReceivedDate'}
                            label={'Date Civil Penalty Assessment Order Received'}
                            rules={[FormValidationUtil.RequiredDate('Date is required')]}>
                            <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                        </FormItem>
                        <FormItem
                            name={'waiverDescription'}
                            label={'Waiver Description'}
                            tooltip={'Provide the specific and detailed reasons/circumstances that prevented timely ' +
                                'filing of the report and that justify a waiver of the civil penalty rule'}
                            rules={[
                                FormValidationUtil.Required('Waiver Description is required'),
                                FormValidationUtil.Max(4000, 'Max Length: 4000 characters')
                            ]}>
                            <TextArea />
                        </FormItem>
                        <Row gutter={8}>
                            <Col {...columnSizing}>
                                <FormItem
                                    name={'signature'}
                                    label={'Petitioner\'s Signature'}
                                    rules={[FormValidationUtil.Required('Signature is required')]}>
                                    <Input type={'text'} />
                                </FormItem>
                            </Col>
                            <Col {...columnSizing}>
                                <FormItem
                                    name={'signedDate'}
                                    label={'Date Signed'}
                                    rules={[FormValidationUtil.RequiredDate('Date Signed is required')]}>
                                    <CustomDatePicker disabled={true} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Space>
                            {id == null ?
                                <>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                    <Button onClick={this.cancelForm}>Cancel</Button>
                                </> :
                                <>
                                    <Button type="primary" htmlType="submit" >Audited</Button>
                                    <Button onClick={this.cancelEdit}>Cancel</Button>
                                </>
                            }
                        </Space>
                    </CustomForm>
                }
            </Content>
        );
    }

    private handleWaiverTypeChange = (value: string) => {
        const petitionForWaiver = { ...this.state.petitionForWaiver };
        petitionForWaiver.penaltyType = value;

        this.formRef.current?.setFieldsValue({ contributorName: null });

        this.clearIds(petitionForWaiver);
        this.setState({
            penaltyType: value,
            petitionForWaiver
        });
    }

    private handleContributorSelect = (value: string, name: string) => {
        const petitionForWaiver = { ...this.state.petitionForWaiver };

        this.clearIds(petitionForWaiver);
        switch (this.state.penaltyType) {
            case PenaltyType.ONETIME_CONTRIBUTIONS:
                petitionForWaiver.otcId = value.toString();
                break;
            case PenaltyType.INDIVIDUAL_INDEPENDENT_EXPENDITURE:
                petitionForWaiver.iieId = value.toString();
                break;
            case PenaltyType.ORGANIZATION_INDEPENDENT_EXPENDITURE:
                petitionForWaiver.oieId = value.toString();
                break;
            case PenaltyType.VERIFIED_STATEMENT_OF_REGISTRATION:
                petitionForWaiver.committeeId = value.toString();
                break;
            case PenaltyType.DR2:
                petitionForWaiver.committeeId = value.toString();
                break;
            case PenaltyType.PERSONAL_FINANCIAL_DISCLOSURES:
                petitionForWaiver.pfdContactId = Number.parseInt(value);
                break;
            default:
                break;
        }

        petitionForWaiver.contributorName = name;
        this.setState({ petitionForWaiver });
    }

    private clearIds = (petitionForWaiver: PetitionForWaiverDTO) => {
        petitionForWaiver.contributorName = null;
        petitionForWaiver.otcId = null;
        petitionForWaiver.iieId = null;
        petitionForWaiver.oieId = null;
        petitionForWaiver.committeeId = null;
        petitionForWaiver.pfdContactId = null;
        petitionForWaiver.clientAssessedPenalty = null;
    }

    private submitForm = (values: PetitionForWaiverDTO) => {
        this.setState({ loading: true });
        values.contributorName = this.state.petitionForWaiver.contributorName;

        CampaignApiService.savePetitionForWaiver({ ...this.state.petitionForWaiver, ...values }).then(() => {
            this.setState({ loading: false });
            if (!this.state.id) {
                HistoryUtil.push(Routes.LOGIN);
                notification.success({
                    message: 'Your report submitted successfully.'
                });
            } else {
                HistoryUtil.push(Routes.MANAGE_PETITION_FOR_WAIVER);
                notification.success({
                    message: 'Your changes were saved successfully.',
                });
            }
        }).catch(() => {
            notification.error({ message: 'Failed to save' });
            this.setState({ loading: false });
        });
    }

    private cancelForm = () => {
        confirm({
            title: 'Are you sure you want to leave?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                HistoryUtil.push(Routes.LOGIN);
            }
        });
    }

    private cancelEdit = () => {
        confirm({
            title: 'Are you sure you want to leave?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                HistoryUtil.push(Routes.MANAGE_PETITION_FOR_WAIVER);
            }
        });
    }

    private getPetitionForWaiver = () => {
        if (!this.state.id) {
            this.setState({ loading: false });
            return;
        }

        return AdminPetitionForWaiverAPIService.getPetitionForWaiver(this.state.id).then((petitionForWaiver) => {
            petitionForWaiver.reportDueDate = moment(petitionForWaiver.reportDueDate);
            petitionForWaiver.reportDateFiled = moment(petitionForWaiver.reportDateFiled);
            petitionForWaiver.penaltyReceivedDate = moment(petitionForWaiver.penaltyReceivedDate);
            petitionForWaiver.signedDate = moment(petitionForWaiver.signedDate);

            this.setState({
                loading: false,
                penaltyType: petitionForWaiver.penaltyType || '',
                petitionForWaiver
            });
        }).catch(() => {
            this.setState({ loading: false });
        });
    }
}

export default PetitionForWaiver;