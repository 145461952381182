import { Col, Layout, Modal, Row, notification } from 'antd';
import React from 'react';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import AdminDualCompListDTO from '../../models/AdminDualCompListDTO';
import AdminDualCompAPIService from '../../api/AdminDualCompAPIService';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import FilingStatusType from '../../consts/FilingStatusType';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';

const { Content } = Layout;
const { confirm } = Modal;

interface ManageDualCompProps {
}

interface ManageDualCompState {
    tableApproveColumns: DataTableColumnProps<AdminDualCompListDTO>[];
    tableManageColumns: DataTableColumnProps<AdminDualCompListDTO>[];
}

class ManageDualComp extends React.Component<ManageDualCompProps, ManageDualCompState> {
    private dataManageTable: DataTable<AdminDualCompListDTO> | undefined;
    private dataApproveTable: DataTable<AdminDualCompListDTO> | undefined;

    constructor(props: ManageDualCompProps) {
        super(props);
        this.state = {
            tableApproveColumns: this.getApprovalTableColumns(),
            tableManageColumns: this.getManageTableColumns()
        };
    }

    render() {
        const manageActionButtons: any = [];
        manageActionButtons.push(DataTableButtonUtil.Reset());

        const approveActionButtons: any = [];
        approveActionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <Row>
                    <Col xs={24}>
                        <DataTable
                            ref={(element: any) => (this.dataApproveTable = element)}
                            serverSide={true}
                            tableProps={{
                                rowKey: 'id',
                                sortDirections: ['ascend', 'descend'],
                                locale: {
                                    emptyText: 'Currently there are no Dual Comp\'s that need to be approved'
                                }
                            }}
                            globalSearch={true}
                            title="Approve Dual Comp"
                            buttonBar={approveActionButtons}
                            columns={this.state.tableApproveColumns}
                            fetchData={{
                                fetch: function (tableRequest) {
                                    return AdminDualCompAPIService.getDualCompList(tableRequest,
                                        FilingStatusType.FILED);
                                },
                                failureMessage: 'Failed to retrieve Dual Comps.'
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                            stateSaving={{
                                enabled: true,
                                tableUniqueKey: 'adminApproveDEBC',
                                perSession: true,
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={24}>
                        <DataTable
                            ref={(element: any) => (this.dataManageTable = element)}
                            serverSide={true}
                            tableProps={{
                                rowKey: 'id',
                                sortDirections: ['ascend', 'descend'],
                                locale: {
                                    emptyText: 'Currently there are no Dual Comp\'s that need to be reviewed'
                                }
                            }}
                            globalSearch={true}
                            title="Manage Dual Comp"
                            buttonBar={manageActionButtons}
                            columns={this.state.tableManageColumns}
                            fetchData={{
                                fetch: function (tableRequest) {
                                    return AdminDualCompAPIService.getDualCompList(tableRequest,
                                        FilingStatusType.AUDITED);
                                },
                                failureMessage: 'Failed to retrieve Approved Dual Comps.'
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                            stateSaving={{
                                enabled: true,
                                tableUniqueKey: 'adminManageDEBC',
                                perSession: true,
                            }}
                        />
                    </Col>
                </Row>
            </Content>
        );
    }

    private getTableColumns = (): DataTableColumnProps<AdminDualCompListDTO>[] => {
        return [
            DataTableColumnUtil.Text('Official or Employee Name', 'officialName'),
            DataTableColumnUtil.Text('Original Executive Agency', 'originalExecutiveAgencyName'),
            DataTableColumnUtil.Text('Secondary Executive Agency', 'secondaryExecutiveAgency'),
            DataTableColumnUtil.Currency('Amount', 'compensationAmount'),
            DataTableColumnUtil.Description('Description', 'serviceDescription'),
            DataTableColumnUtil.Date('Accepted Date', 'acceptanceDate'),
            DataTableColumnUtil.Date('Filed Date', 'filedDate', null, { defaultSortOrder: 'descend' })
        ];
    }


    private getApprovalTableColumns = (): DataTableColumnProps<AdminDualCompListDTO>[] => {
        return this.getTableColumns().concat([
            DataTableColumnUtil.Buttons('Actions', [
                { text: 'View', onClick: (rowData: AdminDualCompListDTO) => this.edit(rowData.id) },
                { text: 'Approve', onClick: (rowData: AdminDualCompListDTO) => this.approve(rowData.id) },
                { text: 'Reject', onClick: (rowData: AdminDualCompListDTO) => this.confirmReject(rowData.id, rowData.officialName || '') },
            ], 220)
        ]);
    }

    private getManageTableColumns = (): DataTableColumnProps<AdminDualCompListDTO>[] => {
        return this.getTableColumns().concat([
            DataTableColumnUtil.Buttons('Actions', [
                { text: 'Edit', onClick: (rowData: AdminDualCompListDTO) => this.edit(rowData.id) },
                { text: 'Delete', onClick: (rowData: AdminDualCompListDTO) => this.confirmDelete(rowData.id, rowData.officialName || '') }
            ], 150)
        ]);
    }

    private edit = (id: number) => {
        HistoryUtil.push(Routes.generate(Routes.EDIT_DUAL_COMP, { id }));
    }

    private approve = (id: number) => {
        AdminDualCompAPIService.approveDualComp(id).then(() => {
            if (this.dataApproveTable) {
                this.dataApproveTable.refresh();
            }
            if (this.dataManageTable) {
                this.dataManageTable.refresh();
            }
            notification.success({
                message: 'Your changes were saved successfully.'
            });
        }).catch(() => {
            notification.error({
                message: 'Failed to approve Dual Comp.'
            });
        });
    }

    private confirmDelete = (id: number, officialName: string) => {
        confirm({
            title: 'Are you sure you want to delete this Dual Comp?',
            content: 'The following record will be permanently deleted: ' + officialName,
            onOk: () => {
                this.delete(id);
            },
        });
    }

    private delete = (id: number) => {
        AdminDualCompAPIService.deleteDualComp(id).then(() => {
                if (this.dataManageTable) {
                    this.dataManageTable.refresh();
                }
                if (this.dataApproveTable) {
                    this.dataApproveTable.refresh();
                }
                notification.success({ message: 'Dual Comp was deleted successfully.' });
            }).catch(() => {
                notification.error({ message: 'Failed to delete Dual Comp.' });
            });
    }

    private reject = (id: number) => {
        AdminDualCompAPIService.rejectDualComp(id).then(() => {
                if (this.dataApproveTable) {
                    this.dataApproveTable.refresh();
                }
                notification.success({ message: 'Dual Comp was rejected successfully.' });
            }).catch(() => {
                notification.error({
                    message: 'Failed to reject Dual Comp.'
                });
            });
    }

    private confirmReject = (id: number, officialName: string) => {
        confirm({
            title: 'Are you sure you want to reject this Dual Comp Approval?',
            content: 'The following record will be rejected: ' + officialName,
            onOk: () => {
                this.reject(id);
            },
        });
    }
}

export default ManageDualComp;