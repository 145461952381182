import { Col, Layout, Modal, Row, notification } from 'antd';
import * as React from 'react';
import AdminSalesByStateDTO from '../../models/AdminSalesByStateDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import AdminSalesByStateService from '../../api/AdminSalesByStateService';
import FilingStatusType from '../../consts/FilingStatusType';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';

const { Content } = Layout;
const { confirm } = Modal;

interface ManageSalesByStateProps {
}

interface ManageSalesByStateState {
    tableApproveColumns: DataTableColumnProps<AdminSalesByStateDTO>[];
    tableManageColumns: DataTableColumnProps<AdminSalesByStateDTO>[];
}

class ManageSalesByState extends React.Component<ManageSalesByStateProps, ManageSalesByStateState> {
    private dataApproveTable: DataTable<AdminSalesByStateDTO> | undefined;
    private dataManageTable: DataTable<AdminSalesByStateDTO> | undefined;

    constructor(props: ManageSalesByStateProps) {
        super(props);
        this.state = {
            tableApproveColumns: this.getTableColumns('approve'),
            tableManageColumns: this.getTableColumns('manage')
        };
    }

    render() {
        const approveActionButtons: any = [];
        approveActionButtons.push(DataTableButtonUtil.Reset());

        const manageActionButtons: any = [];
        manageActionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <Row>
                    <Col xs={24}>
                        <DataTable
                            ref={(element: any) => (this.dataApproveTable = element)}
                            serverSide={true}
                            tableProps={{
                                rowKey: 'salesByStateId',
                                sortDirections: ['ascend', 'descend'],
                                locale: {
                                    emptyText: 'Currently there are no Sales by Officials/Employees to approve.'
                                }
                            }}
                            globalSearch={true}
                            title={'Approve Sales by Officials/Employees'}
                            buttonBar={approveActionButtons}
                            columns={this.state.tableApproveColumns}
                            fetchData={{
                                fetch: function (tableRequest) {
                                    return AdminSalesByStateService.getSalesByStateList(tableRequest,
                                        FilingStatusType.FILED);
                                },
                                failureMessage: 'Failed to retrieve Sales by Officials/Employees.'
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                            stateSaving={{
                                enabled: true,
                                tableUniqueKey: 'adminApproveSaleByState',
                                perSession: true,
                            }}
                        />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <DataTable
                            ref={(element: any) => (this.dataManageTable = element)}
                            serverSide={true}
                            tableProps={{
                                rowKey: 'salesByStateId',
                                sortDirections: ['ascend', 'descend'],
                                locale: {
                                    emptyText: 'Currently there are no Sales by Officials/Employees to review.'
                                }
                            }}
                            globalSearch={true}
                            title="Manage Sales by Officials/Employees"
                            buttonBar={manageActionButtons}
                            columns={this.state.tableManageColumns}
                            fetchData={{
                                fetch: function (tableRequest) {
                                    return AdminSalesByStateService.getSalesByStateList(tableRequest,
                                        FilingStatusType.AUDITED);
                                },
                                failureMessage: 'Failed to retrieve Sales by Officials/Employees.'
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                            stateSaving={{
                                enabled: true,
                                tableUniqueKey: 'adminManageSalesByState',
                                perSession: true
                            }}
                        />
                    </Col>
                </Row>
            </Content>
        );
    }

    private getTableColumns(tableName: string) {
        const columns: DataTableColumnProps<AdminSalesByStateDTO>[] = [];

        const columnOfficialName: DataTableColumnProps<AdminSalesByStateDTO> = DataTableColumnUtil.Text('Official Name', 'officialName');
        columns.push(columnOfficialName);

        const columnOriginalExecutiveBranchAgencyName: DataTableColumnProps<AdminSalesByStateDTO> =
            DataTableColumnUtil.Text('Original Executive Branch Agency Name', 'executiveBranchAgencyName');
        columns.push(columnOriginalExecutiveBranchAgencyName);

        const columnDateOfSale: DataTableColumnProps<AdminSalesByStateDTO> = DataTableColumnUtil.Date('Date of Sale', 'dateOfSale');
        columns.push(columnDateOfSale);

        const columnSaleAmount: DataTableColumnProps<AdminSalesByStateDTO> = DataTableColumnUtil.Currency('Sale Amount', 'saleAmount');
        columns.push(columnSaleAmount);

        const columnSaleDescription: DataTableColumnProps<AdminSalesByStateDTO> =
            DataTableColumnUtil.Description('Sale Description', 'saleDescription');
        columns.push(columnSaleDescription);

        const columnFiledDate: DataTableColumnProps<AdminSalesByStateDTO> =
            DataTableColumnUtil.Date('Filed Date', 'filedDate', null, { defaultSortOrder: 'descend' });
        columns.push(columnFiledDate);

        if (tableName === 'approve') {
            const approveButtons = [
                { text: 'View', onClick: (rowData: AdminSalesByStateDTO) => this.goToSalesByStateDetails(rowData.id) },
                { text: 'Approve', onClick: (rowData: AdminSalesByStateDTO) => this.approve(Number(rowData.id)) },
                { text: 'Reject', onClick: (rowData: AdminSalesByStateDTO) => this.confirmReject(Number(rowData.id), rowData.officialName || '') }
            ];
            const columnApprove: DataTableColumnProps<AdminSalesByStateDTO> = DataTableColumnUtil.Buttons('salesByStateId', approveButtons, 220);
            columns.push(columnApprove);
        }
        else if (tableName === 'manage') {
            const manageButtons = [
                { text: 'Edit', onClick: (rowData: AdminSalesByStateDTO) => this.goToSalesByStateDetails(rowData.id) },
                { text: 'Delete', onClick: (rowData: AdminSalesByStateDTO) => this.confirmdelete(Number(rowData.id), rowData.officialName || '') }
            ];
            const columnManage: DataTableColumnProps<AdminSalesByStateDTO> = DataTableColumnUtil.Buttons('salesByStateId', manageButtons, 150);
            columns.push(columnManage);
        }

        return columns as DataTableColumnProps<AdminSalesByStateDTO>[];
    }

    private approve = (id: number) => {
        AdminSalesByStateService.approve(id)
            .then(() => {
                if (this.dataManageTable) {
                    this.dataManageTable.refresh();
                }
                if (this.dataApproveTable) {
                    this.dataApproveTable.refresh();
                }
                notification.success({
                    message: 'Your changes were saved successfully.'
                });
            }).catch(() => {
                notification.error({
                    message: 'Failed to approve Sale by Official/Employee.'
                });
            });
    }

    private reject = (id: number) => {
        AdminSalesByStateService.rejectSaleByState(id)
            .then(() => {
                if (this.dataManageTable) {
                    this.dataManageTable.refresh();
                }
                if (this.dataApproveTable) {
                    this.dataApproveTable.refresh();
                }
                notification.success({
                    message: 'Sale by Official/Employee was rejected successfully.'
                });
            }).catch(() => {
                notification.error({
                    message: 'Failed to reject Sale by Official/Employee.'
                });
            });
    }

    private confirmReject = (id: number, name: string) => {
        confirm({
            title: 'Are you sure you want to reject this Sale by Official/Employee?',
            content: 'The following record will be rejected: ' + name,
            onOk: () => {
                this.reject(id);
            },
        });
    }

    private delete = (id: number) => {
        AdminSalesByStateService.delete(id)
            .then(() => {
                if (this.dataManageTable) {
                    this.dataManageTable.refresh();
                }
                if (this.dataApproveTable) {
                    this.dataApproveTable.refresh();
                }
                notification.success({
                    message: 'Sale by Official/Employee was deleted Successfully.'
                });
            }).catch(() => {
                notification.error({
                    message: 'Failed to delete Sale by Official/Employee'
                });
            });
    }

    private confirmdelete = (id: number, name: string) => {
        confirm({
            title: 'Are you sure you want to delete this Sale by Official/Employee?',
            content: 'The following record will be permanently deleted: ' + name,
            onOk: () => {
                this.delete(id);
            },
        });
    }

    private goToSalesByStateDetails(sbsId?: number) {
        if (sbsId) {
            HistoryUtil.push(Routes.generate(Routes.EDIT_SALES_BY_STATE, { sbsId: sbsId }));
        }
    }
}

export default ManageSalesByState;