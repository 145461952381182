import * as React from 'react';
import { Layout, Modal, notification } from 'antd';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import AdminPetitionForWaiverDTO from '../../models/AdminPetitionForWaiverDTO';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import AdminPetitionForWaiverAPIService from '../../api/AdminPetitionForWaiverAPIService';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import LookupsApiService from '../../api/LookupsApiService';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';

const { Content } = Layout;
const { confirm } = Modal;

interface ManagePetitionForWaiverProps {
}

interface ManagePetitionForWaiverState {
    types: CodeLookupTableDTO[];
}

class ManagePetitionForWaiver extends React.Component<ManagePetitionForWaiverProps, ManagePetitionForWaiverState> {
    private dataTable: DataTable<AdminPetitionForWaiverDTO> | undefined;

    constructor(props: ManagePetitionForWaiverProps) {
        super(props);

        this.state = {
            types: []
        };
    }

    componentDidMount() {
        this.fetchTypes();
    }

    render() {
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.dataTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'id',
                        sortDirections: ['ascend', 'descend'],
                        locale: {
                            emptyText: 'Currently there are no Petition For Waiver\'s that need to be reviewed',
                        }
                    }}
                    globalSearch={true}
                    title="Manage Petition for Waiver"
                    buttonBar={actionButtons}
                    columns={this.getTableColumns()}
                    fetchData={{
                        fetch: function (tableRequest) {
                            return AdminPetitionForWaiverAPIService.getPetitionForWaiverList(tableRequest);
                        },
                        failureMessage: 'Failed to retrieve Petitions for Waiver'
                    }}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                    stateSaving={{
                        enabled: true,
                        tableUniqueKey: 'adminManagePetitionForWaiver',
                        perSession: true,
                    }}
                />
            </Content>
        );
    }

    private getTableColumns = (): DataTableColumnProps<AdminPetitionForWaiverDTO>[] => {
        const columns: DataTableColumnProps<AdminPetitionForWaiverDTO>[] = [];
        columns.push(DataTableColumnUtil.Text('Committee Or Individual', 'officialName'));
        columns.push(DataTableColumnUtil.DropdownMulti(
            'Waiver Report Type',
            'penaltyType',
            (this.state.types || []).map(s => (
                { text: s.code || '', value: s.code || '' }))
        ));
        columns.push(DataTableColumnUtil.Address(
            'Filer',
            'submitteeOfficialName',
            (r) => ({
                name: r.submitteeOfficialName,
                line1: r.addressLine1,
                line2: r.addressLine2,
                city: r.city,
                state: r.stateCd,
                zip: r.zip,
            }),
            null,
            { minWidth: 150 }
        ));

        columns.push(DataTableColumnUtil.Date('Filed Date', 'reportDateFiled'));
        columns.push(DataTableColumnUtil.Date('Due Date', 'reportDueDate'));
        columns.push(DataTableColumnUtil.Currency('Amount', 'penaltyAmount'));
        columns.push(DataTableColumnUtil.Date('Received Date ', 'penaltyReceivedDate', null, { defaultSortOrder: 'descend' }));
        columns.push(DataTableColumnUtil.Description('Description ', 'waiverDescription'));
        columns.push(DataTableColumnUtil.Buttons('Actions', [
            { text: 'Edit', onClick: (rowData: AdminPetitionForWaiverDTO) => this.edit(rowData.id) },
            { text: 'Delete', onClick: (rowData: AdminPetitionForWaiverDTO) => this.confirmDelete(rowData.id, rowData.officialName) }
        ], 150));

        return columns;
    }

    private confirmDelete = (id: number, name: string | null) => {
        confirm({
            title: 'Are you sure you want to delete this Petition for Waiver?',
            content: 'The following record will be permanently deleted: ' + name,
            onOk: () => {
                this.delete(id);
            },
        });
    }

    private delete = (id: number) => {
        AdminPetitionForWaiverAPIService.deletePetitionForWaiver(id)
            .then(() => {
                if (this.dataTable) {
                    this.dataTable.refresh();
                }
                notification.success({ message: 'Petition for Waiver was deleted successfully.' });
            }).catch(() => {
                notification.error({ message: 'Failed to delete Petition for Waiver.' });
            });
    }

    private edit = (id: number) => {
        HistoryUtil.push(Routes.generate(Routes.EDIT_PETITION_FOR_WAIVER, { id }));
    }

    private fetchTypes = () => {
        LookupsApiService.getPenaltyTypes()
            .then((types) => {
                this.setState({ types });
            })
            .catch((error: any) => {
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    }
}

export default ManagePetitionForWaiver;