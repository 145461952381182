import {
    Button,
    Col,
    FormInstance,
    FormProps,
    Input,
    Modal,
    Row,
    Space,
    Typography,
    notification
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import CustomForm from '../../shared/CustomForm';
import DualCompDTO from '../../../models/DualCompDTO';
import FormItem from 'antd/lib/form/FormItem';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import CustomDatePicker from '../../shared/CustomDatePicker';
import moment from 'moment';
import CurrencyInput from '../../shared/CurrencyInput';
import TextArea from 'antd/lib/input/TextArea';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import OriginalAgencySearch from '../../shared/OriginalAgencySearch';
import CampaignApiService from '../../../api/CampaignApiService';
import { MaskedInput } from 'antd-mask-input';
import AdminDualCompAPIService from '../../../api/AdminDualCompAPIService';
import OriginalExecAgencyDTO from '../../../models/OriginalExecAgencyDTO';
import FilingStatusType from '../../../consts/FilingStatusType';

const { confirm } = Modal;

interface DualCompProps {
    id?: string;
}

interface DualCompState {
    id?: number;
    loading: boolean;
    dualComp: DualCompDTO;
}

class DualComp extends React.Component<RouteComponentProps<DualCompProps> & FormProps, DualCompState>
{
    private readonly formRef = React.createRef<FormInstance>();

    constructor(props: RouteComponentProps<DualCompProps> & FormProps) {
        super(props);

        this.state = {
            id: props.match.params.id ? parseInt(props.match.params.id) : undefined,
            loading: true,
            dualComp: DualCompDTO.create({ acceptedDate: null, signatureDate: moment().startOf('day') }),
        };
    }

    componentDidMount() {
        this.getDualComp();
    }

    render() {
        const { loading, dualComp, id } = this.state;

        const columnSizing = { xs: 24, md: 12 };

        return (
            <Content className="content-pad">
                <Row>
                    <Typography.Title level={4}>Dual Executive Branch Compensation</Typography.Title>
                </Row>
                {!loading &&
                    <CustomForm
                        formRef={this.formRef}
                        onFinish={this.submitForm}
                        initialValues={dualComp}
                        layout={'vertical'}
                        validateTrigger={['onChange', 'onBlur']}>
                        <Typography.Title level={5}>
                            Official or Employee Name
                        </Typography.Title>
                        <Row gutter={8}>
                            <Col flex={1}>
                                <FormItem
                                    name={'officialFirstName'}
                                    label={'First Name'}
                                    rules={[FormValidationUtil.Required('First Name is required')]} >
                                    <Input type="text" />
                                </FormItem>
                            </Col>
                            <Col flex="50px">
                                <FormItem
                                    name={'officialMiddleInitial'}
                                    label={'MI:'}>
                                    <Input type="text" />
                                </FormItem>
                            </Col>
                            <Col flex={1}>
                                <FormItem
                                    name={'officialLastName'}
                                    label={'Last Name'}
                                    rules={[FormValidationUtil.Required('Last Name is required')]} >
                                    <Input type="text" />
                                </FormItem>
                            </Col>
                        </Row>
                        <FormItem name="originalAgencyId" hidden={true}>
                            <Input hidden={true} disabled={true} aria-hidden={true} />
                        </FormItem>
                        <OriginalAgencySearch
                            name={'originalAgencyLabel'}
                            label={'Original Agency Name'}
                            required={true}
                            onSelect={this.selectAgency}
                        />
                        <FormItem
                            name={'phone'}
                            label={'Phone'}
                            rules={[
                                FormValidationUtil.PhoneOrNumber(),
                                FormValidationUtil.Required('Phone is required')
                            ]}>
                            <MaskedInput mask="(111) 111-1111" />
                        </FormItem>
                        <FormItem
                            name={'email'}
                            label={'Email'}
                            rules={[
                                FormValidationUtil.Required('Email is required'),
                                FormValidationUtil.Email('Email must be in a valid email format')
                            ]}>
                            <Input type="email" />
                        </FormItem>
                        <FormItem
                            name={'secondaryAgencyName'}
                            label={'Secondary Agency Name'}
                            rules={[FormValidationUtil.Required('Agency Name is required')]}>
                            <Input type={'text'} />
                        </FormItem>
                        <FormItem
                            name={'compensationAmount'}
                            label={'Compensation Amount'}
                            rules={[
                                FormValidationUtil.RequiredNumber('Amount is required'),
                                FormValidationUtil.PositiveNumber('Amount must be positive')
                            ]}>
                            <CurrencyInput />
                        </FormItem>
                        <FormItem
                            name={'serviceDescription'}
                            label={'Service Description'}
                            rules={[
                                FormValidationUtil.Required('Service Description is required'),
                                FormValidationUtil.Max(4000, 'Max Length: 4000 characters')
                            ]}>
                            <TextArea />
                        </FormItem>
                        <FormItem
                            name={'acceptedDate'}
                            label={'Accepted Date'}
                            rules={[FormValidationUtil.RequiredDate('Accepted Date is required')]}>
                            <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                        </FormItem>
                        <Row gutter={8}>
                            <Col {...columnSizing}>
                                <FormItem
                                    name={'signature'}
                                    label={'Signature'}
                                    rules={[FormValidationUtil.Required('Signature is required')]}>
                                    <Input type={'text'} />
                                </FormItem>
                            </Col>
                            <Col {...columnSizing}>
                                <FormItem
                                    name={'signatureDate'}
                                    label={'Date Signed'}
                                    rules={[FormValidationUtil.RequiredDate('Date Signed is required')]}>
                                    <CustomDatePicker disabled={true} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Space>
                            {id == null ?
                                <>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                    <Button onClick={this.cancelForm}>Cancel</Button>
                                </> :
                                <>
                                    {dualComp?.filingStatus == FilingStatusType.FILED ?
                                        <>
                                            <Button type="primary" htmlType="submit" >Approve</Button>
                                            <Button onClick={() =>
                                                this.confirmReject(id, `${dualComp?.officialLastName},${dualComp?.officialFirstName}` || '')}>
                                                Reject
                                            </Button>
                                        </> :
                                        <Button type="primary" htmlType="submit" >Save</Button>
                                    }
                                    <Button onClick={this.cancelEdit}>Cancel</Button>
                                </>
                            }
                        </Space>
                    </CustomForm>
                }
            </Content>
        );
    }

    private confirmReject = (id: number, name: string) => {
        confirm({
            title: 'Are you sure you want to reject this Dual Comp?',
            content: 'The following record will be rejected: ' + name,
            onOk: () => {
                this.reject(id);
            },
        });
    }

    private reject = (id: number) => {
        this.formRef.current?.validateFields().then(() => {
            AdminDualCompAPIService.rejectDualComp(id).then(() => {
                HistoryUtil.push(Routes.MANAGE_DUAL_COMP);
                notification.success({
                    message: 'Your changes were saved successfully.'
                });
            }).catch(() => {
                notification.error({
                    message: 'Failed to reject Dual Comp.'
                });
            });
        }).catch(reason => {
            this.formRef?.current?.scrollToField(reason.errorFields[0].name);
            window.scrollBy(0, -150);
        });
    }

    private submitForm = (values: any) => {
        this.formRef.current?.validateFields().then(() => {
            this.setState({ loading: true });
            const saveDualCompPromise = CampaignApiService.saveDualComp({ ...this.state.dualComp, ...values });
            let adjustDualCompPromise;
            if (this.state.id) {
                adjustDualCompPromise = AdminDualCompAPIService.approveDualComp(this.state.id);
            }

            return Promise.all([saveDualCompPromise, adjustDualCompPromise]).then(() => {
                this.setState({ loading: false });
                if (!this.state.id) {
                    HistoryUtil.push(Routes.LOGIN);
                    notification.success({
                        message: 'Your report submitted successfully.',
                        description: 'You will be contacted by the IECDB staff upon approval. Thank You.'
                    });
                } else {
                    HistoryUtil.push(Routes.MANAGE_DUAL_COMP);
                    notification.success({
                        message: 'Your changes were saved successfully.',
                    });
                }
            }).catch(() => {
                notification.error({ message: 'Failed to save.' });
                this.setState({ loading: false });
            });
        }).catch(reason => {
            this.setState({ loading: false });
            this.formRef?.current?.scrollToField(reason.errorFields[0].name);
            window.scrollBy(0, -150);
        });
    }

    private cancelForm = () => {
        confirm({
            title: 'Are you sure you want to leave?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                HistoryUtil.push(Routes.LOGIN);
            }
        });
    }

    private cancelEdit = () => {
        confirm({
            title: 'Are you sure you want to leave?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                HistoryUtil.push(Routes.MANAGE_DUAL_COMP);
            }
        });
    }

    private selectAgency = (agency: OriginalExecAgencyDTO | undefined) => {
        if (agency != null) {
            this.formRef.current?.setFieldsValue({ originalAgencyId: agency.id });
        }
    }

    private getDualComp = () => {
        if (!this.state.id) {
            this.setState({ loading: false });
            return;
        }

        this.setState({ loading: true });
        AdminDualCompAPIService.getDualComp(this.state.id).then((dualComp) => {
            dualComp.acceptedDate = moment(dualComp.acceptedDate);
            dualComp.signatureDate = moment(dualComp.signatureDate);

            this.setState({
                dualComp,
                loading: false
            });
        }).catch(() => {
            this.setState({ loading: false });
        });
    }
}

export default DualComp;